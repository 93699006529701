export function IcX() {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.0312 2.38574L8.66262 8.57838L4.00195 13.6133H5.05088L9.13129 9.20515L12.4281 13.6133H15.9977L11.1057 7.0723L15.4438 2.38574H14.3949L10.637 6.44553L7.60074 2.38574H4.0312ZM5.57373 3.15838H7.21358L14.4549 12.8405H12.8151L5.57373 3.15838Z"
        fill="black"
      />
    </svg>
  );
}
