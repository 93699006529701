import {
  passTokenReducer,
  setPassTokenFilters,
  setSearching,
  setTokenTabIndex,
} from '@/state/passToken';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import ModuleCoins from './Coins';
import MyItemContainer from './MyItem/MyItem.Container';
import s from './styles.module.scss';
import OnboardingBanner from "@/modules/AlphaPWA/PassToken/OnboardingBanner";

const ModulePassToken = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const params = router.query;
  const tokenTabIndex = params?.token_tab
    ? Number(params.token_tab)
    : useSelector(passTokenReducer).tokenTabIndex;

  const onChangeTab = (i: number) => {
    if (i === 1) {
      dispatch(
        setPassTokenFilters({
          sort_col: 'created_at',
          sort_type: '0',
        })
      );
    } else if (i === 0) {
      dispatch(
        setPassTokenFilters({
          sort_col: 'buy_price',
          sort_type: '0',
        })
      );
    } else if (i === 2) {
      dispatch(
        setPassTokenFilters({
          sort_col: 'percent',
          sort_type: '0',
        })
      );
    }
    dispatch(setTokenTabIndex(i));
    dispatch(setSearching(false));
  };

  return (
    <>
      <MyItemContainer />
      <Tabs
        isLazy
        defaultIndex={tokenTabIndex}
        onChange={onChangeTab}
        height={'100%'}
      >
        <TabList className={s.tabList}>
          <Tab>Top</Tab>
          <Tab>New</Tab>
          <Tab>Trending</Tab>
          {/* <ButtonTokenSearch /> */}
        </TabList>
        <TabPanels className={s.tabPanels}>
          <TabPanel>
            <ModuleCoins />
          </TabPanel>
          <TabPanel>
            <ModuleCoins />
          </TabPanel>
          <TabPanel>
            <OnboardingBanner />
            <ModuleCoins />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default ModulePassToken;
