import { IPassTokenSelect } from '@/services/interfaces/token';
import { Flex, Spinner, Text } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Avatar from '../../Profiles/TradeKey/components/avatar';
import { getUrlAvatarTwitter } from '@/utils/helpers';
import s from './styles.module.scss';
import IcCoin from '../Components/icCoin';
import { compareString, formatName, getExplorer } from '@/utils';
import { getPrice } from '../Item';
import { colors } from '@/utils/color';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import InfoTooltip from '../../Profiles/TradeKey/components/infoTooltip';
import { MdWarning } from 'react-icons/md';
import { isString } from 'lodash';

interface IConfirmItem {
  selectToken: IPassTokenSelect;
  setNumComplete: any;
}

const ConfirmItem: React.FC<IConfirmItem> = ({
  selectToken,
  setNumComplete,
}) => {
  const [loading, setLoading] = useState(false);
  const [txSuccess, setTxSuccess] = useState<any>(undefined);
  const [txError, setTxError] = useState<any>(undefined);
  const refBuying = useRef('');

  useEffect(() => {
    window.addEventListener(
      'message',
      event => {
        if (isString(event.data)) {
          const parsedData = JSON.parse(event.data);
          if (parsedData) {
            if (
              parsedData?.status &&
              !compareString(refBuying.current, parsedData?.status) &&
              compareString(parsedData?.address, selectToken.address)
            ) {
              refBuying.current = parsedData?.status;
              console.log('parsedData', parsedData);

              if (parsedData?.status === 'processing') {
                setLoading(true);
              } else if (parsedData?.status === 'success') {
                setTxSuccess(parsedData?.tx_hash);
                setTxError(undefined);
                setLoading(false);
                setNumComplete((v: number) => v + 1);
              } else if (parsedData?.status === 'error') {
                setTxError(parsedData?.message);
                setTxSuccess(undefined);
                setLoading(false);
                setNumComplete((v: number) => v + 1);
              }
            }
          }
        }
      },
      false
    );
  }, []);

  const renderStatus = useMemo(() => {
    if (txSuccess) {
      return (
        <>
          <BsFillCheckCircleFill
            fontSize={'14px'}
            color={colors.greenPrimary}
          />
          <Text
            onClick={() =>
              window.open(getExplorer(txSuccess, 'nos', 'tx'), '_blank')
            }
            style={{
              color: colors.greenPrimary,
            }}
            textDecorationLine={'underline'}
          >
            Successfully
          </Text>
        </>
      );
    }
    if (txError) {
      return (
        <InfoTooltip label={txError}>
          <Flex alignItems={'center'} gap={2}>
            <MdWarning fontSize={'14px'} color={colors.redPrimary} />
            <Text
              style={{
                color: colors.redPrimary,
              }}
            >{`Failed`}</Text>
          </Flex>
        </InfoTooltip>
      );
    }

    if (loading) {
      return (
        <>
          <Spinner color={colors.redSecondary} />
          <Text
            style={{
              color: colors.redSecondary,
            }}
          >
            Buying
          </Text>
        </>
      );
    }
    return (
      <>
        <Text opacity={0.7}>Waiting</Text>
      </>
    );
  }, [loading]);

  return (
    <Flex className={s.itemModalContainer}>
      <Flex maxW={'50%'} flex={1} gap={`10px`} alignItems={'center'}>
        <Avatar
          url={getUrlAvatarTwitter(
            selectToken?.user_twitter_avatar as string,
            'normal'
          )}
          address={selectToken?.address}
          name={selectToken?.user_twitter_name}
          width={32}
          fontSize={14}
        />
        <Flex maxW={'95%'} flex={1} gap={'4px'} direction={'column'}>
          <Flex gap={2} alignItems={'center'}>
            <Text className={s.buyer}>
              {formatName(selectToken?.user_twitter_name as string, 50)}
            </Text>
            <Flex>
              <IcCoin />
            </Flex>
          </Flex>
          <Flex alignItems={'center'} gap={'4px'}>
            <Text className={s.buyer}>${getPrice(selectToken).usdt}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex alignItems={'center'} gap={2}>
        {renderStatus}
      </Flex>
    </Flex>
  );
};

interface IConfirmItems {
  selectTokens: IPassTokenSelect[];
  setNumComplete: (e: any) => void;
}

const ConfirmItems: React.FC<IConfirmItems> = ({
  selectTokens,
  setNumComplete,
}): any => {
  const renderContent = () => {
    if (selectTokens.length > 0) {
      return selectTokens.map(s => (
        <ConfirmItem
          key={s.address}
          selectToken={s}
          setNumComplete={setNumComplete}
        />
      ));
    }

    return <></>;
  };

  return renderContent();
};

export default ConfirmItems;
