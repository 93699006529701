import ModalJoinFromFT from '@/components/ModalJoinFromFT';
import { TOKEN_TAB_INDEX } from '@/constants/storage-key';
import { CurrencyContext } from '@/contexts/currency-context';
import { ClientOs } from '@/enums/client';
import { detectClientOs } from '@/utils/client';
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import cs from 'classnames';
import { useContext, useState } from 'react';
import SwapTcToBTCModal from '../Profile/SwapTcToBtcModal';
import ProTradeKeyModule from '../Profiles/TradeKey/pro';
import KeyIcon from './img/KeyIcon';
import TokenIcon from './img/TokenIcon';
import SwapIcon from './img/SwapIcon';
import s from './styles.module.scss';
import ModulePassToken from '../PassToken';
import SwapModule from '../Swap';
import { useRouter } from 'next/router';
import { isNaN } from 'lodash';
import withPWAMiddleware from '@/hocs/withAlphaMiddleware';

export enum TabIndex {
  Swap = 0,
  Tokens = 0,
  Keys = 2,
  Coins = 3,
  Trading = 4,
  Token = 5,
}

const ModulePageKeys = () => {
  const clientOs = detectClientOs();
  const {
    isOpen: isOpenSwapTC,
    onOpen: onOpenEnterSwapTC,
    onClose: onCloseEnterSwapTC,
  } = useDisclosure({ id: 'swap_tc_btc' });
  const { isForceConvert } = useContext(CurrencyContext);
  const router = useRouter();
  const params = router.query;

  const localIndex: number = isNaN(
    Number(localStorage.getItem(TOKEN_TAB_INDEX))
  )
    ? TabIndex.Tokens
    : Number(localStorage.getItem(TOKEN_TAB_INDEX));

  const [indexTab, setIndexTab] = useState<number>(
    params?.tab ? Number(params.tab) : localIndex
  );

  return (
    <>
      <Flex className={s.container}>
        {/* <InvitePanel /> */}
        {isForceConvert && (
          <Flex
            bg={'rgb(219, 242, 255)'}
            justifyContent={'center'}
            alignItems={'center'}
            // onClick={showCreatePostModal}
            onClick={onOpenEnterSwapTC}
            height={'28px'}
            className={s.createModalBg}
          >
            <Text
              fontSize={'12px'}
              fontWeight={400}
              color={'rgb(76, 158, 235)'}
              className={s.text}
            >
              The default currency on NBC is BTC. Convert your TC to BTC now
            </Text>
          </Flex>
        )}
        <Tabs
          className={s.tabs}
          isLazy
          // lazyBehavior="keepMounted"
          index={indexTab}
          onChange={k => {
            setIndexTab(k);
            localStorage.setItem(TOKEN_TAB_INDEX, k.toString());
          }}
          isFitted
          height={'100%'}
        >
          <TabList className={s.tabList}>
            {/* <Tab className={s.tab} display={'none'}>
              <SwapIcon />
              <span>Swap</span>
            </Tab> */}
            <Tab className={s.tab}>
              {/* <TokenIcon /> */}
              <span>Tokens</span>
            </Tab>
            <Tab className={s.tab}>
              {/* <KeyIcon /> */}
              <span>Keys</span>
            </Tab>

            {/* <Tab className={s.tab}>
              <CoinIcon />
              <span>Coins</span>
            </Tab> */}
          </TabList>
          <TabPanels
            className={cs(s.tabPanels, {
              [`${s.ios}`]: clientOs === ClientOs.Ios,
            })}
          >
            {/* <TabPanel className={s.tabPanel}>
              <SwapModule isReplaceRouter={false} />
            </TabPanel> */}
            <TabPanel className={s.tabPanel}>
              <ModulePassToken />
            </TabPanel>
            <TabPanel className={s.tabPanel}>
              <ProTradeKeyModule />
            </TabPanel>

            {/* <TabPanel className={s.tabPanel}>
              <ModuleCoin />
            </TabPanel> */}
          </TabPanels>
        </Tabs>
        {/* <ProTradeKeyModule /> */}
      </Flex>
      <SwapTcToBTCModal isOpen={isOpenSwapTC} onClose={onCloseEnterSwapTC} />
      <ModalJoinFromFT />
    </>
  );
};

export default withPWAMiddleware(ModulePageKeys);
