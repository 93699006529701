import {Flex, Text} from "@chakra-ui/react";
import {ONBOARDING_TWITTER_PROFILE} from "@/constants/storage-key";
import {closeModal, openModal} from "@/state/modal";
import s from "./styles.module.scss";
import React from "react";
import {useDispatch} from "react-redux";
import UseCodeModal from "./UseCodeModal";

const CLAIM_GIFT_CODE_MODAL_NAME = 'CLAIM_GIFT_CODE_MODAL_NAME';

const OnboardingBanner = () => {
  const dispatch = useDispatch();

  const twitterProfile = JSON.parse(localStorage.getItem(ONBOARDING_TWITTER_PROFILE) as string);

  const onOpenModal = () => {
    const close = () => dispatch(closeModal({id: CLAIM_GIFT_CODE_MODAL_NAME}));
    dispatch(
      openModal({
        id: CLAIM_GIFT_CODE_MODAL_NAME,
        theme: 'dark',
        title: 'Claim 1,000 Uniswap Intern tokens',
        className: s.modalContent,
        modalProps: {
          centered: true,
          zIndex: 9999999,
          // size: mobileScreen ? 'full' : 'xxl',
        },
        hideCloseButton: false,
        render: () => (
          <UseCodeModal onClose={close}/>
        ),
      })
    )
  }

  return twitterProfile && !twitterProfile?.issued && (
    <Flex justifyContent={"space-between"} alignItems={"center"} bg={"#A1E3CB"} p={"10px 16px"} onClick={onOpenModal}>
      <Text fontSize={"14px"} fontWeight={600} color={"#000000 !important"}>
        Enter your invite code to get free swaps
      </Text>
      <Text bg={"#FFFFFF"} color={"#000000 !important"} fontWeight={500} borderRadius={"100px"} padding={"6px 12px"}>
        Claim
      </Text>
    </Flex>
  )
};

export default OnboardingBanner;
