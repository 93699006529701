import CPassTokenAPI from '@/services/classes/passToken';
import { IPassToken } from '@/services/interfaces/token';
import React, { useContext, useEffect, useRef, useState } from 'react';
import MyItem from '.';
import { compareString } from '@/utils';
import { AssetsContext } from '@/contexts/assets-context';
import { WalletContext } from '@/contexts/wallet-context';

const MyItemContainer = () => {
  const passTokenAPI = useRef(new CPassTokenAPI()).current;
  const [passToken, setPassToken] = useState<IPassToken | undefined>(undefined);
  const { addressL2 } = useContext(WalletContext);

  useEffect(() => {
    fetchData();
  }, [addressL2]);

  const fetchData = async () => {
    try {
      const response = await passTokenAPI.getPassTokens({
        page: 1,
        limit: 99999,
        key_type: '1',
      });
      const findMyKey = response.rows.find(v =>
        compareString(v.owner, addressL2)
      );
      if (findMyKey) {
        setPassToken(findMyKey);
      }
    } catch (error) {
    } finally {
    }
  };

  if (!passToken) {
    return <></>;
  }

  return <MyItem row={passToken} />;
};

export default MyItemContainer;
