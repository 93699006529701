import { ROUTE_PATH } from '@/constants/route-path';
import { WalletContext } from '@/contexts/wallet-context';
import { IPassToken } from '@/services/interfaces/token';
import {
  abbreviateNumber,
  compareString,
  formatCurrency,
  formatName,
} from '@/utils';
import { getUrlAvatarTwitter } from '@/utils/helpers';
import { Flex, Td, Text, Tr } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import Avatar from '../../Profiles/TradeKey/components/avatar';
import MyItem from '../MyItem';

import BigNumber from 'bignumber.js';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { MAX_DECIMAL, MIN_DECIMAL } from '../../Profiles/TradeKey/constants';
import IcCoin from '../Components/icCoin';
import { estimateFundTokenBalance } from '../Tokens/buttonDepositFund';
import styles from '../Tokens/styles.module.scss';

interface IItemTokenDeposit {
  row: IPassToken;
}

export const getMarketCap = (row: IPassToken) => {
  const { btc2Usd } = useContext(WalletContext);
  const marketCapBTC = new BigNumber(row?.price || '0')
    .multipliedBy(row?.total_supply || '0')
    .toString();

  return {
    btc: formatCurrency(marketCapBTC, MIN_DECIMAL, 2, 'BTC'),
    usdt: formatCurrency(
      new BigNumber(btc2Usd?.toString() || '0')
        .multipliedBy(marketCapBTC)
        .toString(),
      MIN_DECIMAL,
      2
    ),
    usdt_abbr: abbreviateNumber(
      new BigNumber(btc2Usd?.toString() || '0')
        .multipliedBy(marketCapBTC)
        .toString()
    ),
  };
};

export const getPrice = (row: IPassToken) => {
  const { btc2Usd } = useContext(WalletContext);
  const tokenPrice = row?.price || '0';

  return {
    btc: formatCurrency(tokenPrice, MIN_DECIMAL, MAX_DECIMAL, 'BTC'),
    usdt: formatCurrency(
      new BigNumber(btc2Usd?.toString() || '0')
        .multipliedBy(tokenPrice)
        .toString(),
      MIN_DECIMAL,
      MAX_DECIMAL
    ),
  };
};

export const getVol = (row: IPassToken) => {
  const { btc2Usd } = useContext(WalletContext);
  const tokenPrice = row?.total_volume || '0';

  return {
    btc: formatCurrency(tokenPrice, MIN_DECIMAL, MAX_DECIMAL, 'BTC'),
    usdt: formatCurrency(
      new BigNumber(btc2Usd?.toString() || '0')
        .multipliedBy(tokenPrice)
        .toString(),
      MIN_DECIMAL,
      MAX_DECIMAL
    ),
  };
};

export const getKeyPrice = (row: IPassToken) => {
  const { btc2Usd } = useContext(WalletContext);
  const tokenPrice = row?.key?.buy_price || '0';

  return {
    btc: formatCurrency(tokenPrice, MIN_DECIMAL, MAX_DECIMAL, 'BTC'),
    usdt: formatCurrency(
      new BigNumber(btc2Usd?.toString() || '0')
        .multipliedBy(tokenPrice)
        .toString(),
      MIN_DECIMAL,
      2
    ),
  };
};

const ItemTokenDeposit: React.FC<IItemTokenDeposit> = ({ row }) => {
  const { addressL2 } = useContext(WalletContext);
  const router = useRouter();

  const handleGoToProfile = async (data: IPassToken) => {
    return router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${data.owner}`);
  };

  const symbol = 'BTC';

  const percent = estimateFundTokenBalance(
    parseEther(row?.fund_balance_btc || '0')
  )
    .div(row?.total_supply || '0')
    .mul(100)
    .toString();

  if (compareString(addressL2, row?.owner)) {
    return <MyItem row={row} />;
  }

  return (
    <Tr className={styles.itemContainer}>
      <Td maxWidth={'60%'}>
        <Flex width={'100%'} maxW={'100%'} gap={`8px`} alignItems={'center'}>
          <Avatar
            url={getUrlAvatarTwitter(
              row?.user_twitter_avatar as string,
              'normal'
            )}
            address={row?.address}
            name={row?.user_twitter_name}
            width={32}
            fontSize={14}
            onClick={() => handleGoToProfile(row)}
          />
          <Flex flex={1} gap={'4px'} direction={'column'}>
            <Flex gap={2} alignItems={'center'}>
              <Text
                onClick={() => handleGoToProfile(row)}
                className={styles.buyer}
              >
                {formatName(row?.user_twitter_name as string, 50)}
              </Text>
              <Flex>
                <IcCoin />
              </Flex>
            </Flex>
            <Flex alignItems={'center'} gap={'4px'}>
              <Text className={styles.desc}>
                <span style={{ opacity: 0.7 }}>Key</span> $
                {getKeyPrice(row).usdt} ·
              </Text>
              <Text className={styles.desc}>
                <span style={{ opacity: 0.7 }}>Supply</span>{' '}
                {abbreviateNumber(row.total_supply)}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Td>
      <Td>
        <Flex
          width={'100%'}
          gap={2}
          direction={'column'}
          alignItems={'flex-end'}
          justifyContent={'center'}
        >
          <Text className={styles.buyer}>
            {formatCurrency(formatEther(percent), 2, 2)}%
            {/* {getMarketCap(row).btc} {symbol} */}
          </Text>
          {/* <Text className={styles.desc}>${getMarketCap(row).usdt}</Text> */}
        </Flex>
      </Td>
      <Td>
        <Flex
          width={'100%'}
          gap={2}
          direction={'column'}
          alignItems={'flex-end'}
        >
          {/* <Text className={styles.buyer}>
            {getPrice(row).btc} {symbol}
          </Text> */}
          <Text style={{ textAlign: 'center' }} className={styles.buyer}>
            {parseFloat(percent) === 0 ? '-' : `$${getMarketCap(row).usdt}`}
          </Text>
        </Flex>
      </Td>
    </Tr>
  );
};

export default ItemTokenDeposit;
