import { AssetsContext } from '@/contexts/assets-context';
import CPassToken from '@/contracts/passToken';
import CTradeAPI from '@/services/classes/trade';
import { IPassToken } from '@/services/interfaces/token';
import { requestReload } from '@/state/common';
import { formatCurrency, getExplorer, requiredAmount } from '@/utils';
import { composeValidators, required } from '@/utils/form-validate';
import { getErrorMessageContract } from '@/utils/helpers';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { formatEther, parseEther } from 'ethers/lib/utils';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Field, Form, useForm, useFormState } from 'react-final-form';
import { useDispatch } from 'react-redux';
import FieldAmount from '../../Profiles/TradeKey/components/form/fieldAmount';
import InputWrapper from '../../Profiles/TradeKey/components/form/inputWrapper';
import HorizontalItem from '../../Profiles/TradeKey/components/horizontalItem';
import {
  showError,
  showSuccess,
} from '../../Profiles/TradeKey/components/toast';
import { MAX_DECIMAL, MIN_DECIMAL } from '../../Profiles/TradeKey/constants';
import s from './styles.module.scss';
import BN from 'bignumber.js';

const onValidateAmount = (value: any, values: any) => {
  const balance = values?.btc_balance;

  if (parseFloat(value) > parseFloat(balance)) {
    return `Max Amount is ${formatCurrency(balance)} BTC`;
  }
  return undefined;
};

const FormIdoDepositFund = ({
  handleSubmit,
  submitting,
  row,
}: {
  handleSubmit: any;
  submitting: boolean;
  row: IPassToken;
}) => {
  const { balanceL2 } = useContext(AssetsContext);

  const { values } = useFormState();
  const { change } = useForm();

  const btc_amount = values?.btc_amount || '0';

  useEffect(() => {
    change('btc_balance', balanceL2?.amountBTCFormatted);
  }, [balanceL2?.amountBTCFormatted]);

  const allocation = useMemo(() => {
    try {
      const _amount =
        new BN(btc_amount || '0')
          .plus(row?.fund_balance_btc || '0')
          .toFixed(18)
          .toString() || '0';

      const estimatePercent = estimateFundTokenBalance(parseEther(_amount)).div(
        row?.total_supply || '0'
      );

      return estimatePercent;
    } catch (error) {
      console.log('estimatePercent', error);
      return BigNumber.from(0);
    }
  }, [row, btc_amount]);

  const onClickMax = () => {
    change('btc_amount', balanceL2.amountBTCFormatted);
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputWrapper
        label="Amount"
        rightLabel={
          <Text>
            Balance:{' '}
            {formatCurrency(
              balanceL2?.amountBTCFormatted,
              MIN_DECIMAL,
              MAX_DECIMAL,
              'BTC'
            )}{' '}
            BTC
          </Text>
        }
      >
        <Field
          name="btc_amount"
          component={FieldAmount}
          validate={composeValidators(
            required,
            requiredAmount,
            onValidateAmount
          )}
          decimals={6}
          onClickMax={onClickMax}
          maxLength={8}
        />
      </InputWrapper>

      <HorizontalItem
        label={<Text>Your total allocation</Text>}
        value={
          <Text>
            {formatCurrency(formatEther(allocation.mul(100).toString()), 2, 2)}%
          </Text>
        }
      />
      <Box mt={8} />
      <Button
        isDisabled={submitting}
        isLoading={submitting}
        type="submit"
        width={'100%'}
      >
        Buy
      </Button>
    </form>
  );
};

interface IButtonIdoDepositFund {
  row: IPassToken;
}

export function estimateFundTokenBalance(_amountIn: BigNumber) {
  const amountIn = _amountIn.mul(99).div(100);

  console.log('amountIn', amountIn.toString());

  const Q96 = BigNumber.from(2).pow(96);
  //
  const liquidity1 = BigNumber.from('274269510988698498800');
  const liquidity2 = BigNumber.from('4724812502269180222007');
  //
  const lowerSqrtPrice = BigNumber.from('4139520951870358439442244');
  const middleSqrtPrice = BigNumber.from('20707574980117841975345668');
  //
  const deltaPrice = amountIn.mul(Q96).div(liquidity1);
  const nextSqrtPrice = lowerSqrtPrice.add(deltaPrice);
  //
  if (nextSqrtPrice.gt(middleSqrtPrice)) {
    //
    const deltaPrice1 = middleSqrtPrice.sub(lowerSqrtPrice);
    const amountOut1 = liquidity1
      .mul(Q96)
      .mul(deltaPrice1)
      .div(lowerSqrtPrice)
      .div(middleSqrtPrice);
    const amountIn1 = deltaPrice1.mul(liquidity1).div(Q96);
    //
    const amountIn2 = amountIn.sub(amountIn1);
    const deltaPrice2 = amountIn2.mul(Q96).div(liquidity2);
    const nextSqrtPrice2 = middleSqrtPrice.add(deltaPrice2);
    const amountOut2 = liquidity2
      .mul(Q96)
      .mul(deltaPrice2)
      .div(middleSqrtPrice)
      .div(nextSqrtPrice2);
    //
    console.log('amountOut1.add(amountOut2)', amountOut1.add(amountOut2));

    return amountOut1.add(amountOut2);
  } else {
    //
    const amountOut1 = liquidity1
      .mul(Q96)
      .mul(deltaPrice)
      .div(lowerSqrtPrice)
      .div(nextSqrtPrice);
    //
    console.log('amountOut1.add(amountOut2)', amountOut1);

    return amountOut1;
  }
}

const ButtonIdoDepositFund: React.FC<IButtonIdoDepositFund> = ({ row }) => {
  const passToken = useRef(new CPassToken()).current;
  const tradeAPI = useRef(new CTradeAPI()).current;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (values: any) => {
    try {
      setSubmitting(true);

      const response = await passToken.makeInvest({
        token_address: row?.address as string,
        btc_amount: values?.btc_amount,
      });
      await tradeAPI.scanTrxAlpha({ tx_hash: response?.hash });
      showSuccess({
        message: 'Bought successfully!',
        url: getExplorer(response?.hash, 'nos', 'tx'),
      });
      dispatch(requestReload());
      onClose();
    } catch (error) {
      console.log('error', error);
      showError(getErrorMessageContract(error));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Button className={s.btnBuy} onClick={onOpen}>
        Buy
      </Button>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>Buy {row.user_twitter_name} token</ModalHeader>
          <ModalBody>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit }) => (
                <FormIdoDepositFund
                  handleSubmit={handleSubmit}
                  submitting={submitting}
                  row={row}
                />
              )}
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ButtonIdoDepositFund;
