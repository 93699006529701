import React, {useContext, useState} from 'react';
import {Box, Button, Text,} from '@chakra-ui/react';
import s from './styles.module.scss';
import {WalletContext} from '@/contexts/wallet-context';
import toast from 'react-hot-toast';
import {Formik} from 'formik';
import OTPInput from 'react-otp-input';
import {useRouter} from "next/router";
import {claimGiftCode} from "@/services/referral";
import {ROUTE_PATH} from "@/constants/route-path";
import {ONBOARDING_TWITTER_PROFILE} from "@/constants/storage-key";

interface IFormValue {
  code: string;
}

const UseCodeModal: React.FC<any> = ({onClose}: any): React.ReactElement => {
  const [submitError, setSubmitError] = useState('');
  const router = useRouter();
  const { addressL2 } = useContext(WalletContext);

  const [loading, setLoading] = useState(false);

  const validateForm = (values: IFormValue): Record<string, string> => {
    const errors: Record<string, string> = {};

    if (!values.code) {
      errors.code = 'Code is required.';
    }

    return errors;
  };

  const handleSubmit = async (payload: IFormValue): Promise<void> => {
    if (loading) return;
    console.log('payload', payload);

    try {
      const res = await claimGiftCode({address: addressL2 as string, code: payload.code});
      toast.success("Claimed 1,000 Uniswap Intern tokens successfully!", {duration: 3000});
      const roomId = "65680dffd0c4271f0a2aa87d";
      router.push(
        `${ROUTE_PATH.ALPHA_MOBILE_DM}?id=${roomId}`,
      );
      localStorage.removeItem(ONBOARDING_TWITTER_PROFILE);
      onClose && onClose();
    } catch (e: any) {
      console.log('error', e);
      toast.error(e?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Text fontSize={"14px"} fontWeight={400} opacity={"60%"} mb={6} mt={-4}>A swap using prompt /uni costs 100 Uniswap Intern tokens.
        <br/><br/>Redeem your invite code to receive 1,000 tokens for 10 free swaps.</Text>
      <Formik
        key="cash-out-form"
        initialValues={{
          code: '',
        }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {({
            values,
            touched,
            errors,
            // handleChange,
            setFieldValue,
            handleSubmit,
          }) => {
          // console.log(values)
          return (
            <form className={s.form} onSubmit={handleSubmit}>
              <div className={s.codeInput}>
                <OTPInput
                  value={values.code}
                  onChange={v => {
                    setSubmitError('');
                    setFieldValue('code', v);
                  }}
                  numInputs={6}
                  renderSeparator={<></>}
                  renderInput={props => <input {...props} />}
                  placeholder="-"
                  inputType="text"
                />
                {!!(errors.code || submitError) && (
                  <div className={s.error}>
                    <Text className="error">
                      {errors.code || submitError}
                    </Text>
                  </div>
                )}
              </div>
              <Button
                isDisabled={
                  !!errors.code ||
                  !!submitError ||
                  loading ||
                  values.code.length < 4
                }
                // onClick={handleConfirm}
                w="100%"
                type="submit"
                className={s.confirm_btn}
              >
                {loading ? 'Processing...' : 'Confirm'}
              </Button>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default React.memo(UseCodeModal);
