import ListTable, { ColumnProp } from '@/components/ListTable';
import CPassTokenAPI from '@/services/classes/passToken';
import {
  IPassToken,
  IPassTokenParams,
  IPassTokenSelect,
} from '@/services/interfaces/token';
import { selectCommonReducer } from '@/state/common';
import { Box, Checkbox, Flex, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '../../Profiles/TradeKey/components/avatar';
import { getUrlAvatarTwitter, getUrlToSwap } from '@/utils/helpers';
import px2rem from '@/utils/px2rem';
import { ROUTE_PATH } from '@/constants/route-path';
import { useRouter } from 'next/router';
import {
  abbreviateNumber,
  compareString,
  formatCurrency,
  formatName,
} from '@/utils';
import s from './styles.module.scss';
import ScrollWrapper from '@/components/ScrollWrapper/ScrollWrapper';
import { IFilter, passTokenReducer, setSearching } from '@/state/passToken';
import { getKeyPrice, getMarketCap, getPrice, getVol } from '../Item';
import IcCoin from '../Components/icCoin';
import { clone } from 'lodash';
import CoinFooter from './footer';
import { BsClock } from 'react-icons/bs';
import moment from 'moment';
import clsx from 'classnames';
import BigNumberBN from 'bignumber.js';
import { WalletContext } from '@/contexts/wallet-context';
import IcTwitter from '../../Profiles/TradeKey/assets/icons/icTwitter';

const DEFAULT_LIMIT = 30;
export const DEFAULT_AMOUNT_BUY = 10000;

const ModuleCoins = () => {
  const router = useRouter();
  const passTokenAPI = useRef(new CPassTokenAPI()).current;

  const dispatch = useDispatch();

  const hasIncrementedPageRef = useRef<boolean>(false);

  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [passTokens, setPassTokens] = useState<IPassToken[]>([]);
  const [selectKeys, setSelectKeys] = useState<IPassTokenSelect[]>([]);
  const needReload = useSelector(selectCommonReducer).needReload;
  const filter = useSelector(passTokenReducer).filter1;
  const searching = useSelector(passTokenReducer).searching;

  const { btc2Usd } = useContext(WalletContext);

  const refParams = useRef<IPassTokenParams & IFilter>({
    page: 1,
    limit: DEFAULT_LIMIT,
    key_type: '2',
  });

  useEffect(() => {
    fetchData(true);
  }, [needReload, JSON.stringify(filter)]);

  const fetchData = async (isNew?: boolean) => {
    try {
      refParams.current = {
        ...refParams.current,
        ...filter,
        search: '',
      };

      if (searching) {
        refParams.current.page = 1;
      }
      const response = await passTokenAPI.getPassTokens(refParams.current);

      const _rows = response.rows;

      if (!isNew) {
        setPassTokens(__rows => [...__rows, ..._rows]);
      } else {
        setPassTokens(_rows);
      }
    } catch (error) {
    } finally {
      hasIncrementedPageRef.current = false;
      setLoading(false);
      dispatch(setSearching(false));
    }
  };

  const onRefresh = async () => {
    try {
      setRefreshing(true);
      refParams.current = {
        ...refParams.current,
        page: 1,
      };
      await fetchData(true);
    } catch (error) {
      console.log('refreshing err', error);
    } finally {
      setRefreshing(false);
    }
  };

  const handleGoToProfile = async (data: IPassToken) => {
    router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${data.owner}`);
  };

  const handleGoToTWProfile = async (data: IPassToken) => {
    return window.open(
      `https://twitter.com/${data.user_twitter_username}`,
      '_blank'
    );
  };

  const onCheckBox = (data: IPassToken) => {
    const _selectKeys: IPassTokenSelect[] = clone(selectKeys);
    const _findIndex = _selectKeys.findIndex(v =>
      compareString(v.address, data.address)
    );
    if (_findIndex >= 0) {
      _selectKeys.splice(_findIndex, 1);
    } else {
      _selectKeys.push({
        buy_amount: DEFAULT_AMOUNT_BUY,
        ...data,
      });
    }
    setSelectKeys(_selectKeys);
  };

  const columns: ColumnProp[] = useMemo(() => {
    if (filter?.sort_col === 'created_at') {
      return [
        {
          id: 'player',
          label: 'Token',
          labelConfig: {
            color: '#898989',
            fontSize: '12px',
            letterSpacing: '-0.5px',
          },
          config: {
            borderBottom: '1px solid var(--alpha-postLine)',
            fontSize: '14px',
            fontWeight: 500,
            verticalAlign: 'middle',
            letterSpacing: '-0.5px',
            width: '55%',
            paddingLeft: '20px',
          },
          render(data: IPassToken, extraData: any, index: number) {
            const isChecked =
              selectKeys.findIndex(v =>
                compareString(v.address, data.address)
              ) >= 0;

            const usdTreasury = new BigNumberBN(
              data?.treasury_amount || '0'
            ).multipliedBy(btc2Usd || '0');

            const renderDesc = () => {
              if (filter?.sort_col === 'created_at') {
                return (
                  <>
                    <BsClock fontSize={'10px'} opacity={'70%'} />
                    <Text
                      color={'#000000'}
                      fontSize={px2rem(12)}
                      opacity={'70%'}
                    >
                      {`${moment(data?.created_at).fromNow()}`}
                    </Text>
                    <span style={{ fontSize: '8px', opacity: 0.7 }}>
                      &#8226;
                    </span>
                    <Text
                      display={'flex'}
                      alignItems={'center'}
                      fontSize={px2rem(12)}
                      gap={'4px'}
                      className={s.followerX}
                      onClick={() => handleGoToTWProfile(data)}
                    >
                      <Box>
                        <IcTwitter />
                      </Box>
                      {abbreviateNumber(data.twitter_followers_count)}
                    </Text>
                  </>
                );
              }
              return (
                <>
                  <Text fontSize={px2rem(12)}>
                    <span style={{ opacity: 0.7 }}>MC</span> $
                    {getMarketCap(data).usdt_abbr}
                  </Text>
                  <span style={{ fontSize: '8px', opacity: 0.7 }}>&#8226;</span>
                  <Text fontSize={px2rem(12)}>
                    <span style={{ opacity: 0.7 }}>Treasury</span> $
                    {formatCurrency(usdTreasury.toString(), 0, 2)}
                  </Text>
                </>
              );
            };

            return (
              <Flex gap={3} alignItems={'center'} width={'100%'}>
                <Box className={s.checkboxContainer}>
                  <Checkbox
                    isChecked={isChecked}
                    onChange={() => onCheckBox(data)}
                  />
                </Box>
                <Flex
                  width={'100%'}
                  maxW={'100%'}
                  gap={2}
                  alignItems={'center'}
                >
                  <Avatar
                    url={getUrlAvatarTwitter(
                      data?.user_twitter_avatar as string,
                      'normal'
                    )}
                    address={data?.address}
                    name={data?.user_twitter_name}
                    width={32}
                    fontSize={14}
                    onClick={() => handleGoToProfile(data)}
                  />
                  <Flex flex={1} gap={'4px'} direction={'column'}>
                    <Flex gap={2} alignItems={'center'}>
                      <Text
                        onClick={() => handleGoToProfile(data)}
                        className={s.buyer}
                      >
                        {formatName(data?.user_twitter_name as string, 50)}
                      </Text>
                      <Flex>
                        <IcCoin />
                      </Flex>
                    </Flex>
                    <Flex alignItems={'center'} gap={'4px'}>
                      {renderDesc()}

                      {/* <Text className={s.buyer}>
                          <span style={{ opacity: 0.7 }}>Key</span> $
                          {getMarketCap(data).usdt} ·
                        </Text>
                        <Text className={s.buyer}>
                          <span style={{ opacity: 0.7 }}>Supply</span>{' '}
                          {abbreviateNumber(data.total_supply)}
                        </Text> */}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            );
          },
        },
        {
          id: 'price',
          label: 'Price',
          labelConfig: {
            color: '#898989',
            fontSize: '12px',
            // bgColor: '#F6F6F6',
            textAlign: 'right',
            letterSpacing: '-0.5px',
          },
          config: {
            borderBottom: '1px solid var(--alpha-postLine)',
            fontSize: '14px',
            fontWeight: 500,
            verticalAlign: 'middle',
            letterSpacing: '-0.5px',
            textAlign: 'right',
            width: '25%',
          },
          render(data: IPassToken) {
            return (
              <Flex
                // onClick={() => onCheckBox(data)}
                onClick={() =>
                  // router.push(getUrlToSwap(data?.owner, '', 0, true))
                  router.push(
                    `${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${data.owner}`
                  )
                }
                justifyContent={'flex-end'}
                flexDirection={'column'}
                gap={'4px'}
              >
                <Text style={{ textAlign: 'right' }} className={s.buyer}>
                  ${getPrice(data).usdt}
                </Text>
                {/* <Text style={{ textAlign: 'right' }} className={s.desc}>
                    Vol: ${getVol(data).usdt}
                  </Text> */}
              </Flex>
            );
          },
        },
        {
          id: '24h',
          label: '24h%',
          labelConfig: {
            color: '#898989',
            fontSize: '12px',
            // bgColor: '#F6F6F6',
            textAlign: 'right',
            letterSpacing: '-0.5px',
          },
          config: {
            borderBottom: '1px solid var(--alpha-postLine)',
            fontSize: '14px',
            fontWeight: 500,
            verticalAlign: 'middle',
            letterSpacing: '-0.5px',
            justifyContent: 'flex-end',
            textAlign: 'right',
          },
          render(data: IPassToken) {
            let cls = s.normal;
            const percent = data?.percent || 0;
            if (percent < 0) {
              cls = s.low;
            } else if (percent >= 0) {
              cls = s.hight;
            }
            return (
              <Flex
                onClick={() =>
                  router.push(getUrlToSwap(data?.owner, '', 0, true))
                }
                justifyContent={'flex-end'}
                flexDirection={'column'}
                gap={'4px'}
                className={clsx(s.percent, cls)}
              >
                <Text className={s.buyer}>
                  {formatCurrency(data.percent, 2, 2)}%
                </Text>
              </Flex>
            );
          },
        },
      ];
    }
    return [
      {
        id: 'player',
        label: 'Token',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          letterSpacing: '-0.5px',
          borderBottom: '1px solid var(--alpha-postLine)',
        },
        config: {
          borderBottom: '1px solid var(--alpha-postLine)',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
          width: '50%',
          paddingLeft: '20px',
        },
        render(data: IPassToken, extraData: any, index: number) {
          const isChecked =
            selectKeys.findIndex(v => compareString(v.address, data.address)) >=
            0;

          const usdTreasury = new BigNumberBN(
            data?.treasury_amount || '0'
          ).multipliedBy(btc2Usd || '0');

          const renderDesc = () => {
            if (filter?.sort_col === 'created_at') {
              return (
                <>
                  <BsClock fontSize={'10px'} opacity={'70%'} />
                  <Text color={'#000000'} fontSize={px2rem(12)} opacity={'70%'}>
                    {`${moment(data?.created_at).fromNow()}`}
                  </Text>
                </>
              );
            }
            return (
              <>
                <Text fontSize={px2rem(12)}>
                  <span style={{ opacity: 0.7 }}>MC</span> $
                  {getMarketCap(data).usdt_abbr}
                </Text>
                <span style={{ fontSize: '8px', opacity: 0.7 }}>&#8226;</span>
                <Text fontSize={px2rem(12)}>
                  <span style={{ opacity: 0.7 }}>Treasury</span> $
                  {formatCurrency(usdTreasury.toString(), 0, 2)}
                </Text>
              </>
            );
          };

          return (
            <Flex gap={3} alignItems={'center'} width={'100%'}>
              <Box className={s.checkboxContainer}>
                <Checkbox
                  isChecked={isChecked}
                  onChange={() => onCheckBox(data)}
                />
              </Box>
              <Flex width={'100%'} maxW={'100%'} gap={2} alignItems={'center'}>
                <Avatar
                  url={getUrlAvatarTwitter(
                    data?.user_twitter_avatar as string,
                    'normal'
                  )}
                  address={data?.address}
                  name={data?.user_twitter_name}
                  width={32}
                  fontSize={14}
                  onClick={() => handleGoToProfile(data)}
                />
                <Flex flex={1} gap={'4px'} direction={'column'}>
                  <Flex gap={2} alignItems={'center'}>
                    <Text
                      onClick={() => handleGoToProfile(data)}
                      className={s.buyer}
                    >
                      {formatName(data?.user_twitter_name as string, 50)}
                    </Text>
                    <Flex>
                      <IcCoin />
                    </Flex>
                  </Flex>
                  <Flex
                    onClick={() => onCheckBox(data)}
                    alignItems={'center'}
                    gap={'4px'}
                  >
                    {renderDesc()}

                    {/* <Text className={s.buyer}>
                        <span style={{ opacity: 0.7 }}>Key</span> $
                        {getMarketCap(data).usdt} ·
                      </Text>
                      <Text className={s.buyer}>
                        <span style={{ opacity: 0.7 }}>Supply</span>{' '}
                        {abbreviateNumber(data.total_supply)}
                      </Text> */}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          );
        },
      },
      {
        id: 'price',
        label: 'Price',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          // bgColor: '#F6F6F6',
          textAlign: 'right',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: '1px solid var(--alpha-postLine)',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
          textAlign: 'right',
          width: '30%',
        },
        render(data: IPassToken) {
          return (
            <Flex
              // onClick={() => onCheckBox(data)}
              onClick={() =>
                // router.push(getUrlToSwap(data?.owner, '', 0, true))
                router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${data.owner}`)
              }
              justifyContent={'flex-end'}
              flexDirection={'column'}
              gap={'4px'}
            >
              <Text style={{ textAlign: 'right' }} className={s.buyer}>
                ${getPrice(data).usdt}
              </Text>
              {/* <Text style={{ textAlign: 'right' }} className={s.desc}>
                  Vol: ${getVol(data).usdt}
                </Text> */}
            </Flex>
          );
        },
      },
      {
        id: '24h',
        label: '24h%',
        labelConfig: {
          color: '#898989',
          fontSize: '12px',
          // bgColor: '#F6F6F6',
          textAlign: 'right',
          letterSpacing: '-0.5px',
        },
        config: {
          borderBottom: '1px solid var(--alpha-postLine)',
          fontSize: '14px',
          fontWeight: 500,
          verticalAlign: 'middle',
          letterSpacing: '-0.5px',
          justifyContent: 'flex-end',
          textAlign: 'right',
        },
        render(data: IPassToken) {
          let cls = s.normal;
          const percent = data?.percent || 0;
          if (percent < 0) {
            cls = s.low;
          } else if (percent >= 0) {
            cls = s.hight;
          }
          return (
            <Flex
              // onClick={() => onCheckBox(data)}
              onClick={() =>
                router.push(getUrlToSwap(data?.owner, '', 0, true))
              }
              justifyContent={'flex-end'}
              flexDirection={'column'}
              gap={'4px'}
              className={clsx(s.percent, cls)}
            >
              <Text className={s.buyer}>
                {formatCurrency(data.percent, 2, 2)}%
              </Text>
            </Flex>
          );
        },
      },
    ];
  }, [selectKeys, filter]);

  const onChangeSweep = (_numSweep: any) => {
    // if (Number(_numSweep) <= placeholders.length) {
    const _selectKeys = passTokens.slice(0, Number(_numSweep));
    setSelectKeys(
      _selectKeys.map(v => ({
        buy_amount: DEFAULT_AMOUNT_BUY,
        ...v,
      }))
    );
    // }
  };

  const onResetData = () => {
    setSelectKeys([]);
  };

  return (
    <Flex className={s.coinContainer}>
      <ScrollWrapper
        onFetch={() => {
          refParams.current = {
            ...refParams.current,
            page: refParams.current.page + 1,
          };
          hasIncrementedPageRef.current = true;
          fetchData();
        }}
        isFetching={loading || refreshing}
        hasIncrementedPageRef={hasIncrementedPageRef}
        onFetchNewData={onRefresh}
        className={s.listContainer}
      >
        {/* <PassTokenFaqs /> */}
        <ListTable
          data={passTokens}
          columns={columns}
          showEmpty={!loading}
          emptyLabel={<Text textAlign={'center'}>No tokens</Text>}
          initialLoading={loading || searching}
          //   ItemListComponent={row => <ItemTokenDeposit row={row} />}
        />
      </ScrollWrapper>
      <CoinFooter
        selectKeys={selectKeys}
        onChangeSweep={onChangeSweep}
        onResetData={onResetData}
        sweepMax={passTokens.length}
      />
    </Flex>
  );
};

export default ModuleCoins;
