import { AssetsContext } from '@/contexts/assets-context';
import { WalletContext } from '@/contexts/wallet-context';
import CPassToken from '@/contracts/passToken';
import { IPassTokenSelect } from '@/services/interfaces/token';
import { requestReload } from '@/state/common';
import { formatCurrency } from '@/utils';
import { formatAmountToClient, getErrorMessageContract } from '@/utils/helpers';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import BN from 'bignumber.js';
import clsx from 'classnames';
import { BigNumber } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_AMOUNT_BUY } from '.';
import HorizontalItem from '../../Profiles/TradeKey/components/horizontalItem';
import {
  showError,
  showSuccess,
} from '../../Profiles/TradeKey/components/toast';
import { labelAmountOrNumberAdds } from '../../Profiles/TradeKey/constants';
import ConfirmItems from './confirmItems';
import s from './styles.module.scss';

interface ICoinFooter {
  selectKeys: IPassTokenSelect[];
  onChangeSweep: (_: number) => void;
  onResetData: () => void;
  sweepMax: number;
}

const CoinFooter: React.FC<ICoinFooter> = ({
  selectKeys = [],
  sweepMax = 0,
  onChangeSweep,
  onResetData,
}) => {
  const passTokenContract = useRef(new CPassToken()).current;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const { btc2Usd } = useContext(WalletContext);
  const { balanceL2 } = useContext(AssetsContext);

  const [submitting, setSubmitting] = useState(false);
  const [numComplete, setNumComplete] = useState<any>(0);

  const buyAmountBTC = useMemo(() => {
    return selectKeys.reduce((p: any, c) => {
      return BigNumber.from(p)
        .add(
          parseEther(
            new BN(c.buy_amount)
              .multipliedBy(c.price || '0')
              .toFixed(18)
              .toString()
          )
        )
        .toString();
    }, '0');
  }, [selectKeys]);

  const buyNumKeys = useMemo(() => selectKeys.length, [selectKeys.length]);
  const allowBuy = useMemo(() => {
    if (!buyAmountBTC) {
      return undefined;
    }
    return BigNumber.from(buyAmountBTC).lt(balanceL2.amountBTC);
  }, [buyAmountBTC, balanceL2]);
  const isDone = useMemo(() => {
    if (numComplete > 0 && numComplete === buyNumKeys) {
      return true;
    }
    return false;
  }, [buyNumKeys, numComplete]);

  const isDisabled = buyNumKeys === 0;

  const onSubmit = async () => {
    if (!selectKeys || buyNumKeys <= 0) {
      return;
    }
    try {
      setSubmitting(true);
      await passTokenContract.buyMultiTokens({
        tokens: selectKeys as any,
      });
      showSuccess({
        message: 'Sweep successfully',
      });
    } catch (error) {
      showError(getErrorMessageContract(error));
    } finally {
      setSubmitting(false);
      dispatch(requestReload());
    }
  };

  const onHandleClose = () => {
    onResetData();
    onClose();
  };

  return (
    <>
      <Flex className={s.footer}>
        <Flex direction={'column'} flex={1} gap={1}>
          <Flex
            flex={1}
            gap={4}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Text className={s.label}>Sweep</Text>
            <Flex justifyContent={'center'} flexDirection={'column'}>
              {/* <Text lineHeight={'100%'} fontWeight={'bold'}>
              {formatCurrency(formatAmountToClient(buyAmountBTC), 0, 6, 'BTC')}{' '}
              BTC
            </Text> */}
              <Text
                //   lineHeight={'100%'}
                //   fontSize={'12px'}
                //   opacity={0.7}
                //   textAlign={'right'}
                lineHeight={'100%'}
                fontWeight={'bold'}
              >
                $
                {formatCurrency(
                  parseFloat(formatAmountToClient(buyAmountBTC) || '0') *
                    parseFloat(btc2Usd?.toString() || '0'),
                  2,
                  2
                )}
              </Text>
            </Flex>
          </Flex>
          <Flex pl={4}>
            <Text className={s.label}>({buyNumKeys})</Text>
            <Slider
              aria-label="slider-ex-1"
              defaultValue={buyNumKeys}
              value={Number(buyNumKeys)}
              max={sweepMax}
              onChange={onChangeSweep}
              ml={10}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Flex>
        </Flex>

        <Flex flexDirection={'column'} py={2}>
          <Flex gap={2}>
            <Button
              isDisabled={isDisabled || submitting}
              onClick={onOpen}
              className={clsx(
                s.btnAction,
                s.btnBuy,
                isDisabled && s.isDisabled,
                !allowBuy && s.isNotAllowBuy
              )}
              flexDirection={'column'}
            >
              <Text>Sweep</Text>
            </Button>
          </Flex>
          {!allowBuy && (
            <Text className={s.errorText}>Not enough BTC balance</Text>
          )}
        </Flex>
      </Flex>
      <Modal
        closeOnOverlayClick={!submitting}
        closeOnEsc={!submitting}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton isDisabled={submitting} />
          <ModalHeader>Sweep tokens</ModalHeader>
          <ModalBody>
            <ConfirmItems
              selectTokens={selectKeys}
              setNumComplete={setNumComplete}
            />
          </ModalBody>
          <ModalFooter
            alignItems={'center'}
            justifyContent={'space-between'}
            gap={'4px'}
            flexDirection={'column'}
          >
            <HorizontalItem
              label={
                <Text>{`Token${labelAmountOrNumberAdds(buyNumKeys)}`}</Text>
              }
              value={buyNumKeys}
              className={s.modalItem}
            />
            <HorizontalItem
              label={<Text>{`The token amount for each`}</Text>}
              value={DEFAULT_AMOUNT_BUY}
              className={s.modalItem}
            />
            <HorizontalItem
              className={s.modalItem}
              label={<Text>The total cost</Text>}
              value={`$${formatCurrency(
                parseFloat(formatAmountToClient(buyAmountBTC) || '0') *
                  parseFloat(btc2Usd?.toString() || '0'),
                2,
                2
              )}`}
            />
            <Box mt={4} />
            <Button
              isDisabled={submitting}
              //   isLoading={submitting}
              width={'100%'}
              onClick={isDone ? onHandleClose : onSubmit}
              loadingText="Processing"
            >
              {submitting
                ? `Processing ${numComplete} / ${buyNumKeys}`
                : isDone
                ? `Close`
                : `Confirm`}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CoinFooter;
