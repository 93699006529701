import Countdown from '@/components/ActivityTopBar/Countdown';
import { ROUTE_PATH } from '@/constants/route-path';
import { IPassToken } from '@/services/interfaces/token';
import { abbreviateNumber, formatCurrency, formatName } from '@/utils';
import { getUrlAvatarTwitter } from '@/utils/helpers';
import { Flex, Text } from '@chakra-ui/react';
import clsx from 'classnames';
import { formatEther, parseEther } from 'ethers/lib/utils';
import moment from 'moment';
import { useRouter } from 'next/router';
import React from 'react';
import Avatar from '../../Profiles/TradeKey/components/avatar';
import IcCoin from '../Components/icCoin';
import { getKeyPrice, getMarketCap } from '../Item';
import ButtonIdoDepositFund, {
  estimateFundTokenBalance,
} from '../Tokens/buttonDepositFund';
import styles from '../Tokens/styles.module.scss';
import s from './styles.module.scss';

interface IMyItemToken {
  row: IPassToken;
}

const MyItem: React.FC<IMyItemToken> = ({ row }) => {
  const router = useRouter();

  const handleGoToProfile = async (data: IPassToken) => {
    return router.push(`${ROUTE_PATH.ALPHA_MOBILE_PROFILE}/${data.owner}`);
  };

  return (
    <Flex
      gap={'10px'}
      flexDirection={'column'}
      className={clsx(styles.itemContainer, s.myItemContainer)}
    >
      <Flex alignItems={'center'} gap={'6px'}>
        <Flex flex={1} gap={2} alignItems={'center'}>
          <Avatar
            url={getUrlAvatarTwitter(
              row?.user_twitter_avatar as string,
              'normal'
            )}
            address={row?.address}
            name={row?.user_twitter_name}
            width={32}
            fontSize={14}
            onClick={() => handleGoToProfile(row)}
          />
          <Flex width={'100%'} direction={'column'}>
            <Flex gap={2} alignItems={'center'} maxWidth={'100%'}>
              <Text
                onClick={() => handleGoToProfile(row)}
                className={styles.buyer}
              >
                {formatName(row?.user_twitter_name as string, 18)}
              </Text>
              <IcCoin />
            </Flex>
            <Flex alignItems={'center'} gap={1}>
              <Text className={styles.buyer}>
                <span style={{ opacity: 0.7 }}>Key</span> $
                {getKeyPrice(row).usdt} ·
              </Text>
              <Text className={styles.buyer}>
                <span style={{ opacity: 0.7 }}>Supply</span>{' '}
                {abbreviateNumber(row.total_supply)}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex flex={0.5} gap={2} direction={'column'} alignItems={'flex-end'}>
          <Text style={{ textAlign: 'right' }} className={s.title}>
            Creator
            <br />
            allocation
          </Text>
          <Text className={styles.buyer}>
            {formatCurrency(
              formatEther(
                estimateFundTokenBalance(
                  parseEther(row?.fund_balance_btc || '0')
                )
                  .div(row?.total_supply || '0')
                  .mul(100)
                  .toString()
              ),
              2,
              2
            )}
            %
          </Text>
        </Flex>
        <Flex flex={0.5} gap={2} direction={'column'} alignItems={'flex-end'}>
          <Text style={{ textAlign: 'right' }} className={s.title}>
            Market
            <br />
            cap
          </Text>
          <Text className={styles.buyer}>${getMarketCap(row).usdt}</Text>
        </Flex>
      </Flex>
      <Flex mt={'12px'} gap={'12px'}>
        <Flex className={s.blockItem}>
          <Text className={s.title}>Launches in</Text>
          <Countdown
            hideIcon={true}
            className={s.value}
            expiredTime={moment(row?.fund_ended_at).format()}
          />
        </Flex>
        <ButtonIdoDepositFund row={row} />
      </Flex>
    </Flex>
  );
};

export default MyItem;
